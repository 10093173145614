import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BreadCrumbs from '../components/common/breadcrumbs'
import ReactHtmlParser from 'react-html-parser';

const BlogPost = ({ data }) => {

  const breadcrumbs = {
    'Home': '/',
    'Blog': '/blog',
    [data.nodeBlogPost.title]: null
  }


  let article_body_elements = new ReactHtmlParser(data.nodeBlogPost.body.processed.split("<br />\n").join('<p></p>'), {
    transform: function transform(node) {
      if (node.type === 'tag' && node.name === 'img') {
        let i = 0;
        for (i = 0; i < data.allFileFile.edges.length; i++) {
          if (data.allFileFile.edges[i].node.uri.url === node.attribs.src &&
            data.allFileFile.edges[i].node.localFile) {
            return <img alt={node.attribs.alt} title={node.attribs.title}  src={data.allFileFile.edges[i].node.localFile.publicURL} />
          }
        }
      }
      return undefined;
    }
  });


  return (
    <Layout pageTitle={data.nodeBlogPost.title}>
      <SEO title={data.nodeBlogPost.title}
        description={data.nodeBlogPost.field_meta_tags.description} />
      <div class="page-blog-details">
        <div className="page-breadcrumbs notification">
          <BreadCrumbs input={breadcrumbs} />
        </div>
        <Img fluid={data.nodeBlogPost.relationships.field_image.localFile.childImageSharp.fluid} />
        {article_body_elements}
        <hr />
        <div class="tags-wrapper">
          <ul className="tags-list" style={{ listStyle: `none` }}>
            {
              data.nodeBlogPost.relationships.field_blog_tags.map(tag => {
                return (
                  <li key={tag.id} style={{ paddingBottom: '3px' }}>
                    <Link to={tag.path.alias} style={{ fontSize: `28px`, fontWeight: `bold`, textDecoration: `underline` }}>
                      {tag.name}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </Layout>
  )
}
export default BlogPost

export const query = graphql`query($path: String!) {
  allFileFile {
    edges {
      node {
        id
        uri {
          url
        }
        localFile {
          publicURL
        }
      }
    }
  }
  nodeBlogPost(path: { alias: { eq: $path } }) {
    id
    body {
      processed
    }
    title
    path {
      alias
    }
    field_meta_tags {
      title
      description
    }
    relationships {
      field_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      field_blog_tags {
        id
        name
        path {
          alias
        }
      }
    }
  }
}`

